<script setup>
import {ref, computed} from 'vue';
import {useUserStore} from '~/store/userStore.js';
import Text from '@/components/atoms/Text.vue';
import ProductPrice from '@/components/molecules/ProductPrice.vue';
import Image from '~/components/atoms/Image.vue';
import ProductLabels from '~/components/molecules/ProductLabels.vue';

const props = defineProps({
  adminInfo: Object,
  nameWidth: String,
  nameHeight: String,
  nameAlign: {
    type: String,
    default: 'left',
  },
  mainImage: String,
  mobileImage: String,
  secondImage: String,
  name: {
    type: String,
    required: true,
  },
  sku: String,
  price: Number,
  status: String,
  unit: {
    type: String,
    required: true,
  },
  oldPrice: Number,
  discount: Number,
  labels: {
    type: Array,
    default: () => [],
  },
  slug: {
    type: String,
    required: true,
  },
  anotherProducts: Array,
  padding: {
    type: String,
    default: '0',
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  restparti_qty: Number,
  restparti_unit_block: String,
});

const userStore = useUserStore();
const activeColor = ref(0);

const changeActiveColor = (index) => {
  activeColor.value = index;
};

const secondPreview = computed(() => {
  if (props.anotherProducts && props.anotherProducts[activeColor.value]) {
    return props.anotherProducts[activeColor.value].image;
  }
  return props.secondImage;
});
</script>

<template>
  <div class="pp">
    <nuxt-link :to="'/shop/' + slug">
      <div class="product-preview" :style="{ padding }">
        <div class="image">
          <div class="img">
            <Image :loading="lazy ? 'lazy' : 'eager'" :mobile-src="mobileImage" :src="mainImage" :alt="name"/>
          </div>
          <ProductLabels :right-direction="true" class="label" :labels="labels"/>
        </div>

        <div class="hover" v-if="secondPreview && $device.isDesktop">
          <div class="img no-flex">
            <Image :loading="lazy ? 'lazy' : 'eager'" class="second-image" :src="secondPreview" :alt="name"/>
          </div>

          <div class="another-colors row g-8" @mouseleave="activeColor = 0" v-if="anotherProducts?.length">
            <nuxt-link
                :to="'/shop/' + product.url"
                class="another-color-product-image hbb"
                @mouseenter="changeActiveColor(index)"
                :class="{ active: activeColor == index }"
                v-for="(product, index) in anotherProducts.slice(0, 4)"
                :key="index"
            >
              <img width="44" height="44" :src="product.image" alt=""/>
            </nuxt-link>
          </div>
        </div>

        <div class="p-mobile-padding g-8">
          <Text
              class="name"
              :style="{ width: nameWidth, height: nameHeight, textAlign: nameAlign ? nameAlign : '' }"
              text-alignment="left"
              v-html="name"
          ></Text>
          <div class="bottom-info row g-8" v-if="price">
            <template v-if="status === 'Restparti'">
              <ProductPrice :force-unit="true" :unit="restparti_unit_block" :digits="0"
                            :price="Math.ceil(price / restparti_qty)"/>
              <Text class="discount" tag="b" type="red">
                {{ restparti_qty }} <span v-html="restparti_unit_block"></span>
              </Text>
            </template>
            <template v-else>
              <ProductPrice :unit="unit" :digits="0" :price="price"/>
              <ProductPrice style="font-weight: 500;" v-if="oldPrice && oldPrice>price" :digits="0" type="old"
                            :unit="unit"
                            :price="oldPrice"/>
              <Text class="discount" tag="b" type="red" v-if="discount > 0">-{{ discount }}%</Text>
            </template>
          </div>
        </div>

        <div v-if="adminInfo && userStore.isAdmin && userStore.showAdmin">
          <div class="row space-between full-width flex-start"
               style=" font-size: 10px; font-weight: 600; color: #707070;">
            <table>
              <tbody>
              <tr>
                <td style="width: 100px;">IS:</td>
                <td>{{ adminInfo.in_stock }}</td>
              </tr>
              <tr>
                <td>AV:</td>
                <td>{{ adminInfo.available }}</td>
              </tr>
              <tr>
                <td>SI:</td>
                <td>{{ adminInfo.samples_in_stock }}</td>
              </tr>
              <tr>
                <td>SA:</td>
                <td>{{ adminInfo.samples_available }}</td>
              </tr>
              </tbody>
            </table>
            <table>
              <tbody>
              <tr>
                <td style="width: 100px;">SS:</td>
                <td>{{ adminInfo.sorting_score }}</td>
              </tr>
              <tr>
                <td>SA:</td>
                <td>{{ adminInfo.sold_all_time }}</td>
              </tr>
              <tr>
                <td>SKU:</td>
                <td>{{ sku }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/variables.scss";

.another-color-product-image {
  display: flex;
}

.discount {
  @include smd {
    font-size: 12px;
  }
}

.img {
  aspect-ratio: 1/1;
}

a {
  @include smd {
    overflow: hidden;
  }
}

.product-preview {
  gap: 5px;
}

.label {
  position: absolute;
  bottom: 8px;
  left: -10px;
  z-index: 9999;

  @include smd {
    bottom: 10px;
    left: 0;
  }
}

.price {
  @include smd {
    font-size: 12px;
  }

  &.old {
    @include smd {
      font-size: 10px;
    }
  }
}

.name {
  font-size: 13px;

  @include smd {
    font-size: 12px;
    height: auto;
    min-height: 32px;
  }
}

.p-mobile-padding {
  @include mobile {
    padding-left: 12px !important;
    padding-right: 0 !important;
  }
}

.pp:nth-child(2n) {
  .p-mobile-padding {
    @include mobile {
      padding-left: 0 !important;
      padding-right: 12px !important;
    }
  }
}

.hover {
  position: absolute;
  top: 0;
  bottom: 0;
  box-shadow: 0 32px 48px rgba(82, 82, 82, .08);
  background-color: $white;
  z-index: 9;
  left: -5px;
  right: -5px;
  opacity: 0;
  transition: all .25s ease-in-out;
  padding: 0 12px;
}

.product-preview:hover .hover {
  opacity: 1;
}

.another-colors {
  margin: auto;
}
</style>
